<template>
  <v-container>
    <create-update ref="createUpdate" :regions="regions" :types="types" :wineries="wineries" :castes="castes" :food="food"
      @deletePhoto="deletePhoto" @updateWineList="updateWineList" @refresh="getAuxItems" />

    <v-speed-dial fab fixed v-model="fab" :bottom="true" :right="true" direction="top" :open-on-hover="false">
      <template v-slot:activator>
        <v-btn v-model="fab" color="blue darken-2" dark large fab>
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-filter-variant</v-icon>
        </v-btn>
      </template>
      <v-btn fab dark large color="green" @click.stop="filterDialog = true">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-btn fab dark large color="primary" class="mb-2" @click="openCreateUpdate()">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-speed-dial>

    <v-row align="center" justify="center">
      <v-col cols="12" lg="10">
        <v-card>
          <v-toolbar class="flex-grow-0" dark color="blue lighten-1">
            <v-card-title class="headline">Os meus vinhos</v-card-title>

            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon rounded @click.stop="updateAllItems">
                    <v-icon v-on="on">mdi-update</v-icon>
                  </v-btn>
                </template>
                <span>Atualizar tudo</span>
              </v-tooltip>
            </v-toolbar-items>
          </v-toolbar>
          <v-data-table v-infinite-scroll="getItems" infinite-scroll-disabled="busy" infinite-scroll-distance="10"
            :headers="headers" :items="items" :loading="loading" hide-default-footer
            :items-per-page.sync="items.length + 10" class="elevation-1">
            <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
            <template v-slot:item.visible="{ item }">
              <v-switch class="switchOverride" @change="handleQuickChange(item, true)" v-model="item.visible" label
                color="success" hide-details></v-switch>
            </template>
            <template v-slot:item.spotlight="{ item }">
              <v-switch class="switchOverride" @change="handleQuickChange(item, false)" v-model="item.spotlight" label
                color="success" hide-details></v-switch>
            </template>
            <template v-slot:item.action="{ item }">

              <v-tooltip top v-if="item.externalID">
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="updateItem(item)">
                    <v-icon v-on="on" color="blue">mdi-update</v-icon>
                  </v-btn>
                </template>
                <span>Atualizar</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="openCreateUpdate(item)">
                    <v-icon v-on="on" color="green">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="deleteItem(item)">
                    <v-icon v-on="on" color="pink">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Eliminar</span>
              </v-tooltip>

            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="filterDialog" persistent max-width="600px">
      <v-card>
        <v-toolbar class="flex-grow-0" dark color="primary">
          <v-card-title>
            <span class="headline">Filtros :</span>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark rounded @click="filterDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field label="Nome" clearable v-model="name"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="year" label="Ano" type="number" :rules="[rules.zero]"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-autocomplete v-model="regionId" :items="regions" item-text="name" item-value="id" label="Região"
                  clearable></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-autocomplete v-model="typeId" :items="types" item-text="description" item-value="id" label="Tipo"
                  clearable></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-autocomplete v-model="wineryId" :items="wineries" item-text="name" item-value="id" label="Vinicola"
                  clearable></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="cleanFilters()">Limpar</v-btn>
          <v-spacer />
          <div class="flex-grow-1"></div>
          <v-btn color="blue darken-1" text @click="filter()">Procurar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="updAllDialog" persistent max-width="600px">
      <v-card>
        <v-progress-linear indeterminate height="25">
          <strong>{{ progress + " de " + progressTotal }}</strong>
        </v-progress-linear>
      </v-card>
    </v-dialog>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import infiniteScroll from "vue-infinite-scroll";
import ServiceWines from "@/servicesLocal/ServiceWines";
import ServiceRegions from "@/servicesLocal/ServiceRegions";
import ServiceTypes from "@/servicesLocal/ServiceTypes";
import ServiceWineries from "@/servicesLocal/ServiceWineries";
import ServiceCastes from "@/servicesLocal/ServiceCastes";
import ServiceFood from "@/servicesLocal/ServiceFood";
import ServiceImages from "@/servicesLocal/ServiceImages";
import CreateUpdate from "./CreateUpdate";
import ServiceWinesMain from "@/services/ServiceWines";
import _ from "lodash";
export default {
  name: "Wines-List",
  directives: {
    infiniteScroll
  },
  components: {
    CreateUpdate
  },
  data: () => ({
    updAllDialog: false,
    progress: 0,
    progressTotal: 0,
    valid: true,
    overlay: false,
    filterDialog: false,
    dialog: false,
    fab: false,
    page: 1,
    headers: [
      { text: "Nome", value: "name" },
      { text: "Ano", value: "year" },
      { text: "Região", value: "Region.name" },
      { text: "Tipo", value: "Type.description" },
      { text: "Vinicola", value: "Winery.name" },
      { text: "Visivél", value: "visible" },
      { text: "Destaque", value: "spotlight" },
      { text: "Preço (€)", value: "price" },
      { text: "Actions", value: "action", sortable: false, align: "end" }
    ],
    items: [],
    regions: [],
    types: [],
    wineries: [],
    castes: [],
    food: [],
    foodAll: [],
    busy: false,
    loading: true,
    savingInfo: false,
    name: null,
    year: null,
    regionId: null,
    typeId: null,
    wineryId: null,
    rules: {
      required: value => !!value || "Campo Obrigatório.",
      isValidInt: value =>
        /(?<=\s|^)\d+(?=\s|$)/.test(value) || "Valor inválido",
      isValidFloat: value =>
        /^[-+]?([0-9]+(\.[0-9]+)?|\.[0-9]+)$/.test(value) || "Valor inválido",
      counter: value =>
        (value && value.length <= 250) ||
        "Campo tem de ter menos de 250 caracteres",
      zero: v => v >= 0 || "Valor tem de ser >= 0",
      hundred: v => v <= 100 || "Valor tem de ser <= 100"
    }
  }),
  methods: {
    cleanFilters() {
      this.name = null;
      this.year = null;
      this.regionId = null;
      this.typeId = null;
      this.wineryId = null;
      this.page = 1;
      this.getItems();
    },
    filter() {
      this.items = [];
      this.page = 1;

      this.getItems();
    },
    async openCreateUpdate(item) {
      try {
        if (item) {
          this.overlay = true;
          let index = this.items.indexOf(item);

          item = await ServiceWines.show(item.id);

          this.overlay = false;
          this.$refs.createUpdate.open(item.data, index);
        } else {
          this.$refs.createUpdate.open(item, -1);
        }
      } catch (error) {
        this.overlay = false;
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    async getItems() {
      try {
        this.busy = true;
        this.loading = true;
        const response = await ServiceWines.index(
          this.name,
          this.year,
          this.regionId,
          this.typeId,
          this.wineryId,
          this.page,
          50,
          null,
          null
        );

        this.items = this.items.concat(response.data);

        this.page++;
        this.busy = false;
        if (response.data.length == 0) {
          this.busy = true;
        }
        this.loading = false;
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
        this.loading = false;
      }
    },
    async deleteItem(item) {
      try {
        if (confirm("Confirma a eliminação deste elemento?")) {
          this.overlay = true;
          await ServiceWines.delete(item.id);
          let index = this.items.indexOf(item);

          if (index != -1) {
            this.items.splice(index, 1);

            this.$store.dispatch("setSnackbar", {});
          }
          this.overlay = false;
        }
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
        this.overlay = false;
      }
    },
    async handleQuickChange(item, isVisible) {
      try {
        this.overlay = true;

        await ServiceWines.addUpdate(item);
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        if (isVisible) {
          item.visible = !item.visible;
        } else {
          item.spotlight = !item.spotlight;
        }
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    async updateWineList(item, index) {
      if (index > -1) {
        Object.assign(this.items[index], item);
      } else {
        this.items.push(item);
      }
    },
    async deletePhoto(srcArray, index) {
      if (confirm("Confirma a eliminação deste elemento?")) {
        try {
          this.overlay = true;
          if (srcArray[index].id) {
            await ServiceImages.delete(srcArray[index].id);
          }
          srcArray.splice(index, 1);
          this.overlay = false;
          this.$store.dispatch("setSnackbar", {});
        } catch (error) {
          this.overlay = false;
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: error
          });
        }
      }
    },
    async updateItem(item) {
      this.overlay = true;
      try {
        if (!confirm("Confirma a atualização deste item?")) {
          this.overlay = false;
          return;
        }

        let index = this.items.indexOf(item);

        let result = await this.updateItemAbs(item.externalID);

        if (result) {
          Object.assign(this.items[index], result);
        }

        this.overlay = false;
        this.$store.dispatch("setSnackbar", {});
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
        this.overlay = false;
      }
    },
    async updateItemAbs(id) {
      let response = await ServiceWinesMain.show(id);

      if (response.data) {
        _.forEach(response.data.Images, img => {
          img.path = process.env.VUE_APP_MAIN_API_URL + img.path;
        });

        if (response.data.Region.image) {
          response.data.Region.image = process.env.VUE_APP_MAIN_API_URL + response.data.Region.image;
        }

        _.forEach(response.data.Food, food => {
          if (food.image) {
            food.image = process.env.VUE_APP_MAIN_API_URL + food.image;
          }
          if (food.icon) {
            food.icon = process.env.VUE_APP_MAIN_API_URL + food.icon;
          }
        });

        let updLocalItem = await ServiceWines.addUpdateImport(response.data);

        return updLocalItem.data;
      } else {
        return null;
      }
    },
    async updateAllItems() {
      try {
        if (!confirm("Confirma a atualização de todos os items?")) {
          return;
        }
        this.updAllDialog = true;

        let itemIds = await ServiceWines.getAllIds();

        this.progress = 0;
        this.progressTotal = itemIds.data.length;

        for (let index = 0; index < itemIds.data.length; index++) {
          this.progress += 1;
          await this.updateItemAbs(itemIds.data[index].externalID);
        }

        this.items = [];
        this.cleanFilters();

        this.updAllDialog = false;
        this.$store.dispatch("setSnackbar", {});
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
        this.updAllDialog = false;
      }
    },
    async uploadImgs(wine, isMain, srcArray) {
      let send = false;
      let formData = new FormData();
      formData.set("isMain", isMain);
      formData.set("type", "1");
      formData.set("id", wine.id);

      _.each(srcArray, img => {
        if (img.id == null) {
          formData.append("file", this.dataURItoBlob(img.path), img.name);
          send = true;
        }
      });

      if (send) {
        await ServiceImages.upload(formData);
      }
    },
    async getRegions(page) {
      try {
        const response = await ServiceRegions.index(null, null, page);
        this.regions = this.regions.concat(response.data);

        if (response.data.length == 0) {
          return;
        } else {
          page = page + 1;
          this.getRegions(page);
        }
      } catch (error) {
        return;
      }
    },
    async getTypes(page) {
      try {
        const response = await ServiceTypes.index(null, page);
        this.types = this.types.concat(response.data);

        if (response.data.length == 0) {
          return;
        } else {
          page = page + 1;
          this.getTypes(page);
        }
      } catch (error) {
        return;
      }
    },
    async getWineries(page) {
      try {
        const response = await ServiceWineries.index(null, null, page);
        this.wineries = this.wineries.concat(response.data);

        if (response.data.length == 0) {
          return;
        } else {
          page = page + 1;
          this.getWineries(page);
        }
      } catch (error) {
        return;
      }
    },
    async getCastes(page) {
      try {
        const response = await ServiceCastes.index(null, null, page);
        response.data.forEach(element => {
          element.active = false;
          this.castes.push(element);
        });

        if (response.data.length == 0) {
          return;
        } else {
          page = page + 1;
          this.getCastes(page);
        }
      } catch (error) {
        return;
      }
    },
    async getFood(page) {
      try {
        const response = await ServiceFood.index(null, page);
        response.data.forEach(element => {
          element.active = false;
          this.food.push(element);
        });

        if (response.data.length == 0) {
          return;
        } else {
          page = page + 1;
          this.getFood(page);
        }
      } catch (error) {
        return;
      }
    },
    dataURItoBlob(dataURI) {
      // convert base64 to raw binary data held in a string
      // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
      var byteString = atob(dataURI.split(",")[1]);

      // separate out the mime component
      var mimeString = dataURI
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];

      // write the bytes of the string to an ArrayBuffer
      var ab = new ArrayBuffer(byteString.length);

      // create a view into the buffer
      var ia = new Uint8Array(ab);

      // set the bytes of the buffer to the correct values
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      // write the ArrayBuffer to a blob, and you're done
      var blob = new Blob([ab], { type: mimeString });
      return blob;
    },
    async getAuxItems() {
      this.overlay = true;
      this.regions = [];
      this.castes = [];
      this.types = [];
      this.wineries = [];
      this.food = [];
      await Promise.all([
        this.getRegions(1),
        this.getTypes(1),
        this.getWineries(1),
        this.getCastes(1),
        this.getFood(1)
      ]);
      this.overlay = false;

    }
  },
  async mounted() {
    await this.getAuxItems();
  }
};
</script>
<style scoped>
.switchOverride {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
</style>
