<template>
  <v-layout justify-center>
    <v-speed-dial fab fixed v-model="fab" :bottom="true" :right="true" direction="top" :open-on-hover="false">
      <template v-slot:activator>
        <v-btn v-model="fab" color="blue darken-2" dark large fab>
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-filter-variant</v-icon>
        </v-btn>
      </template>
      <v-btn fab dark large color="green" @click.stop="filterDialog = true">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-btn fab dark large color="primary" class="mb-2" @click="dialog = true">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-speed-dial>

    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field v-model="editedItem.description" :rules="[rules.required]" clearable
                    label="Nome"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex justify-center" cols="12" sm="12">
                  <v-color-picker v-model="editedItem.hexColor"></v-color-picker>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text :disabled="savingInfo" @click="close">Cancelar</v-btn>
          <v-btn color="blue darken-1" text :loading="savingInfo" @click="save">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container>
      <v-row align="center" justify="center">
        <v-col md="11">
          <v-data-table v-infinite-scroll="getItems" infinite-scroll-disabled="busy" infinite-scroll-distance="10"
            :headers="headers" :items="items" :loading="loading" hide-default-footer
            :items-per-page.sync="items.length + 10" class="elevation-1">
            <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
            <template v-slot:item.discontinued="{ item }">
              <v-icon v-if="item.discontinued" color="green darken-2">done</v-icon>
              <v-icon v-else color="red darken-2">clear</v-icon>
            </template>
            <template v-slot:item.action="{ item }">
              <td class="justify-center layout px-0">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon class="mx-0" @click="editItem(item)">
                      <v-icon v-on="on" color="green">mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon class="mx-0" @click="deleteItem(item)">
                      <v-icon v-on="on" color="pink">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </td>
            </template>
          </v-data-table>

          <v-dialog v-model="filterDialog" persistent max-width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">Filtros :</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field label="Descrição" clearable v-model="name"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="blue darken-1" text @click="filterDialog = false">Fechar</v-btn>
                <v-btn color="blue darken-1" text @click="filter()">Procurar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
    
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-layout>
</template>

<script>
import infiniteScroll from "vue-infinite-scroll";
import ServiceTypes from "@/servicesLocal/ServiceTypes";
export default {
  name: "Types-List",
  directives: {
    infiniteScroll
  },
  data: () => ({
    type: 'hexa',
    valid: true,
    overlay: false,
    filterDialog: false,
    dialog: false,
    fab: false,
    page: 1,
    headers: [
      { text: "Descrição", value: "description" },
      { text: "Actions", value: "action", sortable: false, align: "center" }
    ],
    items: [],
    busy: false,
    loading: true,
    savingInfo: false,
    editedIndex: -1,
    name: null,
    editedItem: {
      description: "",
      id: null
    },
    defaultItem: {
      description: "",
      hexColor: "#FF00FFFF",
      id: null
    },
    rules: {
      required: value => !!value || "Campo Obrigatório."
    }
  }),
  methods: {
    filter() {
      this.items = [];
      this.page = 1;

      this.getItems();
    },
    async getItems() {
      try {
        this.busy = true;
        this.loading = true;
        const response = await ServiceTypes.index(this.description, this.page);

        this.items = this.items.concat(response.data);

        this.page++;
        this.busy = false;
        if (response.data.length == 0) {
          this.busy = true;
        }
        this.loading = false;
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
        this.loading = false;
      }
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      //   this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async deleteItem(item) {
      try {
        if (confirm("Confirma a eliminação deste elemento?")) {
          this.overlay = true;
          await ServiceTypes.delete(item.id);
          let index = this.items.indexOf(item);

          if (index != -1) {
            this.items.splice(index, 1);

            this.$store.dispatch("setSnackbar", {});
          }
          this.overlay = false;
        }
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
        this.overlay = false;
      }
    },
    async save() {
      if (this.$refs.form.validate()) {
        this.savingInfo = true;

        try {
          const response = await ServiceTypes.addUpdate(this.editedItem);
          if (this.editedIndex > -1) {
            Object.assign(this.items[this.editedIndex], response.data);
          } else {
            this.items.push(response.data);
          }

          this.savingInfo = false;
          this.close();
          this.$store.dispatch("setSnackbar", {});
        } catch (error) {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: error
          });
          this.savingInfo = false;
        }
      }
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Novo Tipo" : "Editar Tipo";
    }
  }
};
</script>
