<template>
  <v-container>
    <v-speed-dial fab fixed v-model="fab" :bottom="true" :right="true" direction="top" :open-on-hover="false">
      <template v-slot:activator>
        <v-btn v-model="fab" color="blue darken-2" dark large fab>
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-filter-variant</v-icon>
        </v-btn>
      </template>
      <v-btn fab dark large color="green" @click.stop="filterDialog = true">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-btn fab dark large color="primary" class="mb-2" @click="dialog = true">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-speed-dial>

    <v-dialog v-model="dialog" persistent scrollable>
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>

          <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="12" lg="5">
                  <v-switch v-model="editedItem.visible" class="ma-2" label="Visivél"></v-switch>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="editedItem.name" :rules="[rules.required, rules.counter]" clearable
                    :counter="250" label="Name"></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="12" lg="10">
                  <v-row>
                    <v-col cols="12" sm="12" lg="6">
                      <v-autocomplete v-model="editedItem.MenuItemGroupId" :rules="[rules.required]" :items="groups"
                        item-text="description" item-value="id" clearable label="Grupo" append-outer-icon="mdi-refresh"
                        @click:append-outer="getGroups()"></v-autocomplete>
                    </v-col>

                    <v-col cols="12" sm="12" lg="6">
                      <v-text-field v-model="editedItem.price" label="Preço" prefix="€" type="number"
                        :rules="[rules.isValidFloat, rules.zero]"></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-textarea v-model="editedItem.description" :rules="[rules.required]" clearable label="Descrição"
                        rows="9"></v-textarea>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" sm="12" lg="2">
                  <file-upload ref="fileUpload" :main="1" @spliceDeleted="spliceDeleted" />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <file-upload ref="fileUploadMulti" :main="0" @spliceDeleted="spliceDeleted" />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text :disabled="savingInfo" @click="close">Cancelar</v-btn>
          <v-btn color="blue darken-1" text :loading="savingInfo" @click="save">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row align="center" justify="center">
      <v-col cols="12" lg="10">
        <v-card>
          <v-toolbar class="flex-grow-0" dark color="blue lighten-1">
            <v-card-title class="headline">{{ mainTitle }}</v-card-title>
          </v-toolbar>
          <v-data-table v-infinite-scroll="getItems" infinite-scroll-disabled="busy" infinite-scroll-distance="10"
            :headers="headers" :items="items" :loading="loading" hide-default-footer
            :items-per-page.sync="items.length + 10" class="elevation-1">
            <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
            <template v-slot:item.visible="{ item }">
              <v-switch class="switchOverride" @change="handleQuickChange(item)" v-model="item.visible" label
                color="success" hide-details></v-switch>
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="editItem(item)">
                    <v-icon v-on="on" color="green">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="deleteItem(item)">
                    <v-icon v-on="on" color="pink">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Eliminar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="filterDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Filtros :</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field label="Nome" clearable v-model="name"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field label="Descrição" clearable v-model="description"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="blue darken-1" text @click="filterDialog = false">Fechar</v-btn>
          <v-btn color="blue darken-1" text @click="filter()">Procurar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <gallery ref="gallery" :index="imgIndex" :images="auxImgs" @close="imgIndex = null"></gallery>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import infiniteScroll from "vue-infinite-scroll";
import ServiceMenuItems from "@/servicesLocal/ServiceMenuItems";
import VueGallery from "vue-gallery";
import FileUpload from "../common/FileUpload";
import _ from "lodash";
export default {
  name: "Menu-Item-List",
  props: ["type"],
  directives: {
    infiniteScroll
  },
  components: {
    FileUpload,
    gallery: VueGallery
  },
  data: () => ({
    mainPic: [],
    otherPics: [],
    valid: true,
    overlay: false,
    filterDialog: false,
    dialog: false,
    fab: false,
    page: 1,
    headers: [
      { text: "Nome", value: "name", width: "15%" },
      { text: "Descrição", value: "description", width: "70%" },
      { text: "Visivél", value: "visible", width: "5%" },
      { text: "Operações", value: "action", sortable: false, align: "center", width: "10%" }
    ],
    items: [],
    groups: [],
    busy: false,
    loading: true,
    savingInfo: false,
    editedIndex: -1,
    name: null,
    description: null,
    editedItem: {
      name: "",
      description: "",
      price: 0,
      visible: true,
      id: null
    },
    defaultItem: {
      name: "",
      description: "",
      price: 0,
      visible: true,
      id: null
    },
    rules: {
      required: value => !!value || "Campo Obrigatório.",
      counter: value =>
        (value && value.length <= 250) ||
        "Campo tem de ter menos de 250 caracteres",
      isValidFloat: value =>
        /^[-+]?([0-9]+(\.[0-9]+)?|\.[0-9]+)$/.test(value) || "Valor inválido",
      zero: v => v >= 0 || "Valor tem de ser >= 0"
    },
    auxImgs: [],
    imgIndex: -1
  }),
  methods: {
    filter() {
      this.items = [];
      this.page = 1;

      this.getItems();
    },
    async getGroups() {
      try {
        this.overlay = true;
        const response = await ServiceMenuItems.getGroups(this.type);

        this.groups = response.data;

        this.overlay = false;
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
        this.overlay = false;
      }
    },
    async getItems() {
      try {
        this.busy = true;
        this.loading = true;
        const response = await ServiceMenuItems.index(
          this.type,
          this.name,
          this.description,
          this.page,
          50,
          null
        );

        this.items = this.items.concat(response.data);

        this.page++;
        this.busy = false;
        if (response.data.length == 0) {
          this.busy = true;
        }
        this.loading = false;
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
        this.loading = false;
      }
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);

      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.$nextTick(() => {
        this.$refs.fileUpload.setExistingImages(
          _.filter(item.Images, ["is_main", true])
        );
        this.$refs.fileUploadMulti.setExistingImages(
          _.filter(item.Images, ["is_main", false])
        );
      });
    },
    close() {
      this.dialog = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.$nextTick(() => {
        this.$refs.fileUpload.setExistingImages([]);
        this.$refs.fileUploadMulti.setExistingImages([]);
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async deleteItem(item) {
      try {
        if (confirm("Confirma a eliminação deste elemento?")) {
          this.overlay = true;
          await ServiceMenuItems.delete(item.id);
          let index = this.items.indexOf(item);

          if (index != -1) {
            this.items.splice(index, 1);

            this.$store.dispatch("setSnackbar", {});
          }
          this.overlay = false;
        }
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
        this.overlay = false;
      }
    },
    async save() {
      if (this.$refs.form.validate()) {
        this.savingInfo = true;
        this.editedItem.type = this.type;
        try {
          const response = await ServiceMenuItems.addUpdate(this.editedItem);

          try {
            response.data.Images = response.data.Images.concat(
              await this.$refs.fileUpload.uploadImgs(response.data.id, 2)
            );
          } catch (error) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Erro ao adicionar a imagem principal"
            });
          }

          try {
            response.data.Images = response.data.Images.concat(
              await this.$refs.fileUploadMulti.uploadImgs(response.data.id, 2)
            );
          } catch (error) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Erro ao adicionar a imagens"
            });
          }

          if (this.editedIndex > -1) {
            Object.assign(this.items[this.editedIndex], response.data);
          } else {
            this.items.push(response.data);
          }

          this.savingInfo = false;
          this.close();
          this.$store.dispatch("setSnackbar", {});
        } catch (error) {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: error
          });
          this.savingInfo = false;
        }
      }
    },
    spliceDeleted(id) {
      let index = 0;

      _.each(this.editedItem.Images, item => {
        if (item.id === id) {
          return;
        } else {
          index += 1;
        }
      });

      this.editedItem.Images.splice(index, 1);
    },
    async handleQuickChange(item) {
      try {
        this.overlay = true;

        await ServiceMenuItems.addUpdate(item);
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        item.visible = !item.visible;
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Novo Registo" : "Editar";
    },
    mainTitle() {
      let title = "";
      switch (this.type) {
        case "beverage":
          title = "Bebidas";
          break;
        case "entree":
          title = "Entradas";
          break;
        case "main":
          title = "Pratos Principais";
          break;
        case "dessert":
          title = "Sobremesas";
          break;

        default:
          title = "N/A";
          break;
      }
      return title;
    }
  },
  async mounted() {
    try {
      this.overlay = true;
      await this.getGroups(this.type);

      this.overlay = false;
    } catch (error) {
      this.overlay = false;
    }
  }
};
</script>
<style scoped>
.switchOverride {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
</style>
